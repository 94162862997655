import { lisaApi } from 'services/api/lisaApi'
import {
  DocumentId,
  Label,
  LabelId,
  LabelResponse,
  LisaFile,
  LisaResponse,
  LisaResponseExtended,
  Nullable,
  SortDirection,
  StorageId,
  StructureLabelsResponse,
  TransitionId,
  UserId
} from 'types'
import { A2KDocuments } from 'redux/slices/a2kDocumentsSlice'
import { createHierarchy, selectedLabels } from 'utils/labels'

type LabelsManagementParams = {
  userId: UserId,
  documentId: DocumentId
  labelId: LabelId
  transitionId: TransitionId
}

export const labellingApi = lisaApi.injectEndpoints({
  endpoints: (builder) => ({
    getLabels: builder.query<LabelResponse, TransitionId>({
      query: (transitionId) => ({
        url: 'Labelling/v2/labels',
        params: { transitionId }
      }),
      providesTags: ['Labels'],
      transformResponse: (response: StructureLabelsResponse) => response.structure
    }),
    getDocumentsByLabel: builder.query<A2KDocuments, {
      transitionId: TransitionId
      labelId: Nullable<LabelId> // @todo
      page: number
      pageSize?: number
      sortOrder?: SortDirection,
      sortColumn?: string,
    }>(({
      query: (params) => ({
        url: 'Labelling/v2/documentsbylabel',
        params
      }),
      providesTags: ['Documents']
    })),
    getDocumentsByLabelV1: builder.query<LisaFile[], {
      transitionId: TransitionId
      labelId: LabelId
    }>({
      query: (params) => ({
        url: 'Labelling/documentsbylabel',
        params
      })
    }),
    bulkLabelAssignToDocuments: builder.mutation<LisaResponseExtended, {
      documents: DocumentId[]
      labelsToRemove: LabelId[]
      labelsToAdd: LabelId[]
      userId: UserId
    }>({
      query: (params) => ({
        url: 'Labelling/bulk',
        body: params,
        method: 'POST'
      }),
      invalidatesTags: ['Documents', 'A2KLabels', 'MxHistoryLabels']
    }),
    bulkLabelsAssignToStructure: builder.mutation<LisaResponseExtended, {
      labels: LabelId[],
      userId: UserId
      storageId: StorageId
    }>({
      query: ({
        labels,
        userId,
        storageId
      }) => ({
        url: 'Labelling/bulkunlabelled',
        body: {
          labelIds: labels,
          importStructureId: storageId,
          userId
        },
        method: 'POST'
      }),
      invalidatesTags: ['Documents', 'A2KLabels', 'MxHistoryLabels']
    }),
    cloneToA2K: builder.mutation<LisaResponse, {
      labelId: LabelId
      importStructureId: StorageId
      prefix?: string
      suffix?: string
    }>({
      query: (params) => ({
        url: 'labelling/clone',
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['Documents', 'Labels']
    }),
    getDocumentLabels: builder.query<{
      label: Label,
      selected: Label[]
    }, DocumentId>({
      query: (documentId) => ({
        url: 'Labelling/v2/document',
        params: { documentId }
      }),
      transformResponse: ({ structure }: StructureLabelsResponse) => {
        const label = createHierarchy(structure)
        return {
          label,
          selected: selectedLabels(label)
        }
      }
    }),
    addLabelToDocument: builder.mutation<LisaResponseExtended, LabelsManagementParams>({
      query: (params) => ({
        url: 'Labelling/create',
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['LabelsRecent']
    }),
    removeLabelFromDocument: builder.mutation<LisaResponseExtended, LabelsManagementParams>({
      query: (params) => ({
        url: 'Labelling/delete',
        method: 'PUT',
        body: params
      }),
      invalidatesTags: ['LabelsRecent']
    }),
    getRecentLabels: builder.query<LabelResponse[], {
      transitionId: TransitionId
      documentId: DocumentId
    }>({
      query: (params) => ({
        url: 'labelling/recent',
        params
      }),
      providesTags: ['LabelsRecent']
    }),
    getA2KLabels: builder.query<LabelResponse, {
      transitionId: TransitionId
      showAll: boolean
    }>({
      query: (params) => ({
        url: 'Labelling/structure',
        params
      }),
      providesTags: ['A2KLabels'],
      transformResponse: (response: StructureLabelsResponse) => response.structure
    }),
    getMxHistoryLabels: builder.query<LabelResponse, {
      transitionId: TransitionId
      showAll: boolean
      onlyMxHistory: boolean
      wpStatus: string
      wpStartDateTime: string
      wpEndDateTime: string
      workorderState: string
    }>({
      query: (params) => ({
        url: 'Labelling/structure',
        params
      }),
      providesTags: ['MxHistoryLabels'],
      transformResponse: (response: StructureLabelsResponse) => response.structure
    }),
    addSubLabel: builder.mutation<StructureLabelsResponse, {
      parentId: LabelId
      labelName: string
      transitionId: TransitionId
    }>({
      query: ({
        labelName,
        ...params
      }) => ({
        url: 'labelling/add',
        method: 'POST',
        body: {
          ...params,
          name: labelName
        }
      }),
      invalidatesTags: ['Labels', 'A2KLabels', 'MxHistoryLabels']
    }),
    renameLabel: builder.mutation<StructureLabelsResponse, {
      parentId: LabelId
      labelId: LabelId
      labelName: string
      transitionId: TransitionId
    }>({
      query: ({
        labelName,
        ...params
      }) => ({
        url: 'labelling/rename',
        method: 'POST',
        body: {
          ...params,
          name: labelName
        }
      }),
      invalidatesTags: ['Labels', 'A2KLabels', 'MxHistoryLabels']
    }),
    removeLabel: builder.mutation<StructureLabelsResponse, {
      label: Label
      transitionId: TransitionId
      newLabelName?: string
    }>({
      query: ({
        label,
        transitionId,
        newLabelName
      }) => ({
        url: 'labelling/remove',
        method: 'POST',
        body: {
          parentId: label.parentId,
          labelId: label.labelId,
          transitionId,
          nameNew: newLabelName
        }
      }),
      invalidatesTags: ['Labels', 'A2KLabels', 'MxHistoryLabels']
    })
  })
})

export const {
  useGetLabelsQuery,
  useGetDocumentsByLabelQuery,
  useGetDocumentsByLabelV1Query,
  useBulkLabelAssignToDocumentsMutation,
  useBulkLabelsAssignToStructureMutation,
  useCloneToA2KMutation,
  useGetDocumentLabelsQuery,
  useAddLabelToDocumentMutation,
  useRemoveLabelFromDocumentMutation,
  useGetRecentLabelsQuery,
  useGetA2KLabelsQuery,
  useGetMxHistoryLabelsQuery,
  useAddSubLabelMutation,
  useRenameLabelMutation,
  useRemoveLabelMutation
} = labellingApi
